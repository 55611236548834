import { isProduction, isStaging } from '@/constants'
import { datadogRum } from '@datadog/browser-rum'

import packageInfo from '../../package.json'

const version = packageInfo.version
const isProdOrStage = isProduction || isStaging

export const initializeDatadogRum = () => {
  // Ensure this runs only in the browser and prod or stage envs.
  if (typeof window === 'undefined' || !isProdOrStage) return

  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
    site: 'datadoghq.com',
    service: 'cms-next',
    env: process.env.NEXT_PUBLIC_ETHOS_ENV,
    version: process.env.APP_VERSION || version,
    sessionSampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    //enable heatmaps
    enableExperimentalFeatures: ['clickmap', 'feature_flags'],
    enablePrivacyForActionName: true,
  })
}
