import {
  WILLS_NEW_META_LP,
  WILLS_V3_3,
} from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

const features = {
  control: 'https://app.tomorrow.me/documents/will/intro',
  curatedFluffFlow: 'wills',
  minimalFlow: 'wills-minimal',
}

export const THIRTY_DAY_TRIAL_FLOW = 'wills-30-day-trial'
export const WILLS_ONBOARDING_FLOW = 'wills-onboarding'
export const WILLS_ONBOARDING_TREATMENT_2_FLOW = 'wills-onboarding-t2'
export const WILLS_RISK_FREE_TRIAL = 'wills-risk-free-trial'
export const WILLS_RISK_FREE_TRIAL_T2 = 'wills-risk-free-trial-t2'

const WILLS_RISK_FREE_TRIAL_PLUS_ATTORNEY =
  'wills-risk-free-trial-plus-attorney'
const WILLS_V3_3_T1_FLOW = 'wills-v3-3-t1'
const WILLS_V3_3_T2_FLOW = 'wills-v3-3-t2'

// TODO: these can be removed for baselining
export const WILLS_PAINTED_DOOR_V2_SEPARATE_FLOW = 'wills-pdv2-separate'
export const TRUSTS_PAINTED_DOOR_V2_SEPARATE_FLOW = 'trusts-pdv2-separate'
export const WILLS_PAINTED_DOOR_V2_2_1_TREATMENT_1 = 'wills-pdv2-2-1-t1'

const getWillsMetaLpFlow = (variation: string) => {
  switch (variation) {
    case WILLS_V3_3.VARIATIONS.TREATMENT_1:
      return { flow: WILLS_V3_3_T1_FLOW, isTreatment: true }
    case WILLS_V3_3.VARIATIONS.TREATMENT_2:
      return { flow: WILLS_V3_3_T2_FLOW, isTreatment: true }
    default:
      return { flow: undefined, isTreatment: false }
  }
}

export const useWillsFunnelFlow = () => {
  const { variation, isLoading } = useDecisionForFlag({
    name: WILLS_V3_3.EXPERIMENT_KEY,
    fallbackVariation: WILLS_V3_3.VARIATIONS.CONTROL,
  })

  const {
    variation: willsNewMetaLpVariation,
    isLoading: willsNewMetaLpLoading,
    isEnabled: isWillsNewMetaLpEnabled,
  } = useDecisionForFlag({
    name: WILLS_NEW_META_LP.EXPERIMENT_KEY,
    fallbackVariation: WILLS_NEW_META_LP.VARIATIONS.TREATMENT_1,
  })

  let flow
  let isTreatment = false

  if (isWillsNewMetaLpEnabled) {
    const { flow, isTreatment } = getWillsMetaLpFlow(willsNewMetaLpVariation)

    return {
      isLoading: willsNewMetaLpLoading,
      isTreatment,
      isLeadForm: false,
      flow,
    }
  }

  switch (variation) {
    case WILLS_V3_3.VARIATIONS.CONTROL:
      flow = WILLS_RISK_FREE_TRIAL_PLUS_ATTORNEY
      isTreatment = true
      break
    case WILLS_V3_3.VARIATIONS.TREATMENT_1:
      flow = WILLS_V3_3_T1_FLOW
      isTreatment = true
      break
    case WILLS_V3_3.VARIATIONS.TREATMENT_2:
      flow = WILLS_V3_3_T2_FLOW
      isTreatment = true
      break
    default:
      flow = undefined
  }

  return {
    isLoading,
    isTreatment,
    isLeadForm: false,
    flow,
  }
}
