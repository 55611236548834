import {
  SINGLE_CTA_EXPERIMENT,
  SINGLE_CTA_PRICE_COMPARISON,
} from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useSingleCTAExperiment = () => {
  const { TREATMENT_1, TREATMENT_2, CONTROL } = SINGLE_CTA_EXPERIMENT.VARIATIONS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: SINGLE_CTA_EXPERIMENT.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const {
    OFF,
    VARIANT_1: PriceVariant1,
    VARIANT_2: PriceVariant2,
  } = SINGLE_CTA_PRICE_COMPARISON.VARIATIONS
  const {
    isEnabled: isPriceComparisonEnabled,
    isLoading: isPriceComparisonLoading,
    variation: priceComparisonVariation,
  } = useDecisionForFlag({
    name: SINGLE_CTA_PRICE_COMPARISON.EXPERIMENT_KEY,
    fallbackVariation: OFF,
  })

  const isTreatment1 = isEnabled && variation === TREATMENT_1
  const isTreatment2 = isEnabled && variation === TREATMENT_2

  const isPriceVariant1 = priceComparisonVariation === PriceVariant1
  const isPriceVariant2 = priceComparisonVariation === PriceVariant2

  const isTreatment =
    (isEnabled && (isTreatment1 || isTreatment2)) ||
    (isPriceComparisonEnabled && priceComparisonVariation !== OFF)

  return {
    isLoading: isLoading || isPriceComparisonLoading,
    isPriceComparisonEnabled,
    isPriceVariant1,
    isPriceVariant2,
    isTreatment1,
    isTreatment2,
    isTreatment,
    textConstant: {
      ctaLabel: 'Start applying',
    },
  }
}
