import React from 'react'

import { CloudinaryImage } from 'ethos-design-system'

import styles from './InsurerComparison.module.scss'

const InsurerComparison = () => (
  <div className={styles.container}>
    <header>Compare Ethos to top insurers</header>
    <section>
      <CloudinaryImage
        alt=""
        height={[14, 14, 14, 14]}
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/MassMutual_jjtlvc.png"
        width={[115, 115, 115, 115]}
      />
      <CloudinaryImage
        alt=""
        height={[24, 24, 24, 24]}
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/Prudential_yx6kvh.png"
        width={[111, 111, 111, 111]}
      />
      <CloudinaryImage
        alt=""
        height={[33, 33, 33, 33]}
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/NewYorkLife_zuqy7y.png"
        width={[33, 33, 33, 33]}
      />
      <CloudinaryImage
        alt=""
        height={[20, 20, 20, 20]}
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/Guardian_fdrkqe.png"
        width={[106, 106, 106, 106]}
      />
      <CloudinaryImage
        alt=""
        height={[24, 24, 24, 24]}
        publicId="https://res.cloudinary.com/getethos/image/upload/v1734027687/Northwestern_Mutual_qk3jqa.png"
        width={[193, 193, 193, 193]}
      />
    </section>
    <footer>Rate comparison is only available for select products</footer>
  </div>
)

export default InsurerComparison
